import React, {useState, useEffect, useRef} from 'react';
import logo from './assets/images/logo.svg';
import './App.css';
import SearchBar from "./components/SearchBar/SearchBar";
import Product from "./components/Product/Product";
import mockedProducts from './mocked-products.json';
import NumberStepper from "./components/NumberStepper/NumberStepper";

let isProgrammaticScroll = false;

function App() {
  const numberStepperRef = useRef();
  const [products] = useState(mockedProducts.products);  // Todos los productos
  const [enabled] = useState(mockedProducts.enabled);  // Todos los productos
  const [message] = useState(mockedProducts.message);  // Todos los productos
  const [filteredProducts, setFilteredProducts] = useState(null);  // Productos filtrados para mostrar

  const findHighestPage = (productsArray) => {
    return Math.max(...productsArray.map(product => product.page));
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm === '') {
      setFilteredProducts(null);
      return;
    }

    const isNumber = !isNaN(searchTerm);
    const regex = new RegExp(searchTerm, 'i');  // Insensible a mayúsculas/minúsculas

    if (isNumber) {
      const filtered = products.filter(product => String(product.code).match(regex));
      setFilteredProducts(filtered);
      return;
    }

    // Buscar en 'title'
    const foundInTitle = products.some(product => product.title.match(regex));
    if (foundInTitle) {
      const filtered = products.filter(product => product.title.match(regex));
      setFilteredProducts(filtered);
      return;
    }

    // Buscar en 'name'
    const foundInName = products.some(product => product.name.match(regex));
    if (foundInName) {
      const filtered = products.filter(product => product.name.match(regex));
      setFilteredProducts(filtered);
      return;
    }

    setFilteredProducts([]);
  };


  const groupPagesByPairs = (products) => {
    const groupedByPage = products.reduce((acc, product) => {
      (acc[product.page] = acc[product.page] || []).push(product);
      return acc;
    }, {});

    const pairs = [];
    const pages = Object.keys(groupedByPage).map(Number).sort((a, b) => a - b);

    for (let i = 0; i < pages.length; i += 2) {
      const firstPage = pages[i];
      const secondPage = pages[i + 1];

      const pair = {
        label: secondPage ? `${firstPage}/${secondPage}` : `${firstPage}`,
        products: [...groupedByPage[firstPage], ...(groupedByPage[secondPage] || [])]
      };

      pairs.push(pair);
    }

    return pairs;
  }

  const pairedPages = groupPagesByPairs(products);

  const getUniqueSortedPages = (products) => {
    const pagesSet = new Set();
    products.forEach(item => {
      pagesSet.add(item.page);
    });
    return [...pagesSet].sort((a, b) => a - b);
  }

  const handleNumberStepperChange = (displayNumber) => {
    const pageDiv = document.getElementById(`page-${displayNumber}`);
    const numberStepperContainer = document.querySelector('.numberStepperContainer');
    const productsContainer = document.querySelector('.products');
    const header = document.querySelector('header'); // Seleccionamos el header

    if (pageDiv && numberStepperContainer && productsContainer) {
      const offsetFromTopOfProducts = pageDiv.offsetTop;
      const numberStepperHeight = numberStepperContainer.offsetHeight;
      const headerHeight = header ? header.offsetHeight : 0; // Obtenemos la altura del header, o 0 si no existe

      // Ahora restamos también la altura del header
      const scrollPosition = offsetFromTopOfProducts - numberStepperHeight - headerHeight;

      isProgrammaticScroll = true;

      productsContainer.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    const productsContainer = document.querySelector('.products');

    const onScroll = () => {

      if (isProgrammaticScroll) {
        return;
      }

      const numberStepperBottom = document.querySelector('.numberStepperContainer').getBoundingClientRect().bottom;
      pairedPages.forEach(pair => {
        const pageDiv = document.getElementById(`page-${pair.label}`);
        if (pageDiv) {
          const pageTop = pageDiv.getBoundingClientRect().bottom;
          if (pageTop >= numberStepperBottom && pageTop < window.innerHeight) {
            const pageNumberDiv = pageDiv.querySelector('.page-number');
            const pageNumber = pageNumberDiv.textContent;
            if (numberStepperRef.current) {
              numberStepperRef.current.updateDisplayNumber(pageNumber);
            }
          }
        }
      });
    };

    const onScrollEnd = () => {
      if (isProgrammaticScroll) {
        isProgrammaticScroll = false
      }
    }

    if (productsContainer) {
      productsContainer.addEventListener('scroll', onScroll);
      productsContainer.addEventListener('scrollend', onScrollEnd);
    }

    return () => {
      if (productsContainer) {
        productsContainer.removeEventListener('scroll', onScroll);
        productsContainer.removeEventListener('scrollend', onScrollEnd);
      }
    };
  }, [pairedPages]);

  return (
    enabled ?
    <div className="violetta-container">
      <header>
        <div className="logo"><img src={logo} alt="Violetta"/></div>
        <SearchBar onSearch={handleSearch}/>
        <section className="campaign">
          CAMPAÑA {products[0].campaign}
        </section>
      </header>

      <div className="numberStepperContainer" style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
        {!filteredProducts && products && products.length &&
          <NumberStepper ref={numberStepperRef} onSearch={handleSearch} maxPage={findHighestPage(products)}
                         allowedPages={getUniqueSortedPages(products)}
                         onDisplayNumberChange={handleNumberStepperChange}/>}
      </div>
      <article className="products">
        {filteredProducts === null ? pairedPages.map((pair, index) => (
            <div key={pair.label} id={`page-${pair.label}`}>
              {/* Mostrar el número de las páginas como subtítulo */}
              <div className="page-number">{pair.label}</div>

              <section className="page">
                {pair.products.map(product => (
                  <Product
                    key={product.code}
                    code={product.code.toString()}
                    product={product.title}
                    name={product.name}
                    price={product.price.toFixed(2)}
                    previousPrice={product.priceOld.toFixed(2)}
                  />
                ))}
              </section>

              {/* Agregar el separador si no es el último grupo */}
              {index !== pairedPages.length - 1 && <hr className="separator"/>}
            </div>
          ))
          : filteredProducts && filteredProducts.length ?
            filteredProducts.map(product => (
              <Product
                key={product.code}
                code={product.code.toString()}
                product={product.title}
                name={product.name}
                price={product.price.toFixed(2)}
                previousPrice={product.priceOld.toFixed(2)}
              />
            ))
            :
            <h1 className="products-title">No se encontraron productos</h1>
        }
      </article>
    </div>
      :
      <div class="fullscreen-container">
        <div className="logo"><img src={logo} alt="Violetta"/></div>
        <div className="message">{message ?? 'Los precios aún no están disponibles'}</div>
      </div>
  );
}

export default App;
