import React, {useState, useEffect, useRef, useImperativeHandle, forwardRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './NumberStepper.styles.css';

const NumberStepper = forwardRef(({ maxPage, allowedPages = [], onDisplayNumberChange = () => {}}, ref) => { // Valor por defecto para allowedPages
  const getInitialPage = () => {
    if (allowedPages.length) {
      return Math.min(...allowedPages);
    } else {
      return 1;
    }
  }


  const initialPage = getInitialPage();
  const [rawNumber, setRawNumber] = useState(initialPage);
  const [displayNumber, setDisplayNumber] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const pagePair = Math.ceil(rawNumber / 2);
    const startPage = (pagePair * 2) - 1;
    const endPage = startPage + 1 <= maxPage ? startPage + 1 : startPage;
    const finalPage = startPage === endPage ? `${startPage}` : `${startPage}/${endPage}`;
    setDisplayNumber(finalPage);
    onDisplayNumberChange(finalPage);
  }, [rawNumber]);

  const isPageAllowed = (number) => {
    return allowedPages.length === 0 || allowedPages.includes(number);
  }

  const findClosestAllowed = (number, direction) => {
    while (!isPageAllowed(number) && number >= 1 && number <= maxPage) {
      number += direction;

      if (number < 1 || number > maxPage) {
        break;
      }
    }

    if (!isPageAllowed(number)) {
      if (direction > 0) {
        return Math.max(...allowedPages);
      } else {
        return Math.min(...allowedPages);
      }
    }

    return number;
  };

  const handleDecrement = () => {
    const decremented = rawNumber - 2;
    const closestAllowed = findClosestAllowed(decremented, -1);
    setRawNumber(closestAllowed);
  };



  const handleInputChange = e => {
    const inputValue = e.target.value;

    if (inputValue === '') {
      setRawNumber('');
    } else {
      const numericValue = parseInt(inputValue, 10);
      if (numericValue > maxPage) {
        setRawNumber(maxPage);
      } else if (isPageAllowed(numericValue)) {
        setRawNumber(numericValue);
      } else {
        const closestAllowed = numericValue > rawNumber
          ? findClosestAllowed(numericValue, 1)
          : findClosestAllowed(numericValue, -1);
        setRawNumber(closestAllowed);
      }
    }
  };

  const handleIncrement = () => {
    if (rawNumber + 2 <= maxPage) {
      const incremented = rawNumber + 2;
      const closestAllowed = findClosestAllowed(incremented, 1);
      setRawNumber(closestAllowed);
    } else if (rawNumber + 1 === maxPage && maxPage % 2 !== 0) {
      const incremented = rawNumber + 1;
      const closestAllowed = findClosestAllowed(incremented, 1);
      setRawNumber(closestAllowed);
    }
  };

  const handleEditMode = () => {
    setIsEditing(true);
    setTimeout(() => inputRef.current.focus(), 0);
  };

  const handleExitEditMode = () => {
    if (rawNumber === '' || rawNumber < 1) {
      setRawNumber(getInitialPage());
    }
    setIsEditing(false);
  };

  const updateDisplayNumberFromOutside = (newDisplayNumber) => {
    setDisplayNumber(newDisplayNumber);
    const newRawNumber = parseInt(newDisplayNumber.split('/')[0], 10);
    setRawNumber(newRawNumber);
  };

  useImperativeHandle(ref, () => ({
    updateDisplayNumber: updateDisplayNumberFromOutside
  }));

  return (
    <div className="numberStepperContainer" ref={ref}>
      <button onClick={handleDecrement}>
        <FontAwesomeIcon icon={faChevronLeft} color="#30207D" />
      </button>
      {isEditing ? (
        <input
          ref={inputRef}
          type="number"
          value={rawNumber}
          onChange={handleInputChange}
          onBlur={handleExitEditMode}
          onKeyUp={e => {
            if (e.key === 'Enter') handleExitEditMode();
          }}
        />
      ) : (
        <span onClick={handleEditMode}>{displayNumber}</span>
      )}
      <button onClick={handleIncrement}>
        <FontAwesomeIcon icon={faChevronRight} color="#30207D" />
      </button>
    </div>
  );
});

export default NumberStepper;
